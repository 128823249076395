<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="onClose"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>

      <div class="ml-10 mr-10 mt-5 mb-5">
        <v-text-field
          autofocus
          spellcheck="false"
          ref="inputBox"
          v-model="value"
          @keydown.enter="onUpdate"
          class="foobar"
        >
        </v-text-field>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">Close</v-btn>
        <v-btn color="blue darken-1" text @click="onUpdate">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/*
    code based on https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d
    Credit to eolant
*/

export default {
  data: () => ({
    dialog: false,
    title: null,
    closeCallback: null,
    value: "",
    options: {
      color: "primary",
      width: 360,
      zIndex: 200,
    },
  }),

  methods: {
    open(title, value, options) {
      this.title = title;
      this.value = value;
      this.dialog = true;
      this.options = Object.assign(this.options, options);

      return new Promise((resolve) => {
        this.closeCallback = resolve;
      });
    },

    onUpdate() {
      this.dialog = false;
      if (this.closeCallback) {
        this.closeCallback();
      }
    },

    onClose() {
      this.dialog = false;
    },

    handleKeydown(e) {
      if (e.code === "Enter") {
        this.onUpdate();
      }
    },
  },
};
</script>
