<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="4">
          <div class="ml-5">
            <DataTypeEditor v-bind:value="configData.formats[0]['data-type']">
            </DataTypeEditor>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex md>
          <div>
            <ColumnListEditor
              v-bind:value="configData.formats[0].columns"
              eventName="columns-updated"
            />
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <hr />

    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex md>
          <v-row>
            <v-col cols="5">
              <div>
                <FilenameEditor
                  v-bind:value="configData.formats[0].filename"
                  eventName="format-filename-updated"
                />
              </div>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>

    <v-row justify="center">
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="(item, i) in configData.formats" :key="i">
            <v-expansion-panel-header>{{
              configData.formats[i].name
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container fluid fill-height>
                <v-layout align-center justify-center>
                  <v-flex md>
                    <div>
                      <ColumnListEditor
                        v-bind:value="configData.formats[0].columns"
                        eventName="columns-updated"
                      />
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <prism-editor
      v-model="configData.formats[0]['query-string']"
      language="sql"
      style="height: auto"
    ></prism-editor>
  </div>
</template>

<script>
import api from "@/services/api";

// import theme style
import "@/assets/sprout-editor-dark.css";
import "@/assets/sprout-editor-light.css";

import ColumnListEditor from "@/components/upload/config/ColumnListEditor.vue";
import FilenameEditor from "@/components/upload/config/FilenameEditor.vue";
``;
import DataTypeEditor from "@/components/upload/config/DataTypeEditor.vue";
``;

import PrismEditor from "vue-prism-editor";
// import "vue-prism-editor/dist/VuePrismEditor.css"; // import the styles
import "prismjs/components/prism-sql";
import "@/assets/sprout-prism-dark.css";

export default {
  components: {
    ColumnListEditor,
    FilenameEditor,
    PrismEditor,
    DataTypeEditor,
  },

  created() {},

  beforeDestroy() {
    this.$eventHub.$off("ui-theme-changed");
  },

  destroyed() {},

  data() {
    return {
      cmOptions: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: false,
        line: true,
        mode: "sql",
        lineWrapping: false,
        theme: "sprout-editor-light",
        readOnly: false,
      },

      items: [
        "category-sales",
        "foodservice-sales",
        "warehouse-sales",
        "canteen-selections",
        "market-share",
        "planned-arrivals",
      ],

      dataType: "",

      extensions: [".xlsb", ".xlsm", ".xlsx", ".csv", ".xls"],

      regExp: "24-315\\d",

      configData: {
        "allowed-domains": ["effem.com", "sproutretail.com"],
        "allowed-extensions": [".xlsb", ".xlsm", ".xlsx", ".csv", ".xls"],
        formats: [
          {
            columns: [
              {
                name: "mfg_prod_id",
              },
              {
                name: "prod_desc",
              },
              {
                name: "dist_dc_name",
              },
              {
                name: "spend",
              },
              {
                name: "pkg",
              },
              {
                name: "allowance",
              },
              {
                name: "uom",
              },
            ],
            "data-type": "foodservice-sales",
            extensions: [".csv"],
            filename: {
              contains: ["volume_allowance"],
            },
            name: "foodservice sales",
            "query-string":
              "SELECT\n    'aramark' AS customer_id,\n    mfg_prod_id AS item_id,\n    prod_desc AS item_description,\n    '{{RegexMatch \"_FY_\\\\d{4}_Q\\\\d_[^_]+_(?P<month>[^_]+)_(?P<year>\\\\d{4})_\" Filename | Format \"{month} 1 {year}\"}}' AS transaction_date,\n    dist_dc_name AS distributor_id,\n    spend AS extended_price,\n    pkg AS shipped_qty,\n    allowance AS allowance_amount,\n    CASE UPPER(uom)\n        WHEN 'CASE' THEN 'CS'\n        WHEN 'CT' THEN 'CS'\n        WHEN 'EACH' THEN 'EA'\n        ELSE ERROR(PRINTF('Unexpected uom (%s)', uom))\n    END AS unit_of_measure,\n    UUID() AS _inlet_id,\n    'OK' AS _dup_status\nFROM tbl_sales;\n",
          },
        ],
        "ignored-extensions": [".png", ".jpg", ".gif"],
        "pipeline-id": 55,
        "pipeline-name": "Aramark",
      },
    };
  },

  methods: {
    getSQL() {
      return this.configData.formats[0]["query-string"];
    },

    pushNotification() {
      const notification = {
        type: "success",
        message: "This is the error message...",
        details: "These are really important error details.... read them!",
      };

      this.$store.dispatch("notification/add", notification);
    },

    test404() {
      api.throw404();
    },

    testing() {
      // api.renameUploadFile("foo", "bar", "nar").then(response => {
      //console.log(response);
      // });
    },

    refresh() {
      //console.log("In refresh method");
    },
  },
};
</script>

<style></style>
