<template>
  <div class="upload-config-control">
    <v-row>
      <v-combobox
        append-icon
        chips
        deletable-chips
        multiple
        v-model="computedValue"
        v-bind:label="titleText"
        v-bind:rules="rulesRunner"
        ref="inputBox"
        v-on:keydown="onKeyDown"
      >
      </v-combobox>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="list-button mt-5"
            v-on:click="clearValue"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Clear</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="list-button mt-5"
            v-on:click="revertValue"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Undo all changes</span>
      </v-tooltip>

      <v-btn
        v-if="!!helpURL()"
        icon
        link
        class="list-button mt-5"
        :href="helpURL()"
        target="_blank"
      >
        <v-icon size="18" class="mt-0" color="blue darken-1">mdi-help</v-icon>
      </v-btn>
    </v-row>
    <sr-msg-box ref="msgbox"></sr-msg-box>
  </div>
</template>

<script>
/*

  the prop "Value" is a Javascript array... therefore, it is passed by reference.
  As a result, any changes to it will affect the parent component. Which is considered
  an anti-patter in Vue. Therefore, I'm making two copies here. orignalValue and
  modifiedValue. I will emit a change when the this control modifies this list
  (which will modifiy the extension prop that was passed by reference). So, to support
  the "revert" functionality I need to save off a copy of the value originally passed
  into the control. There maybe a better way of doing this. But, after a few days of
  research I decided on this method and it seems to work.

*/

import {
  getUploadConfigHelpURL,
  isString,
  lowerCase,
  formatExtension,
  deepEqual,
} from "@/lib/utils.js";
import { hasValue, isValidDomain } from "@/lib/validators/common.js";

export default {
  _intervalID: null,
  _lastValue: null,

  props: {
    value: {
      type: Array,
      default: () => [],
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    helpSection: {
      type: String,
    },
    eventName: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      modifiedValue: [],
      orignalValue: [],
      mapFunctions: [],
      rulesRunner: [this._rulesRunner],
      rules: [],
      loaded: false,
    };
  },

  computed: {
    titleText() {
      if (this.options && this.options.required) {
        return `${this.title} *`;
      } else {
        return this.title;
      }
    },

    computedValue: {
      get: function () {
        if (this.options.sorted) {
          return this.modifiedValue.slice().sort();
        } else {
          return this.modifiedValue.slice();
        }
      },

      set: function (newValue) {
        let x = newValue;

        if (this.mapFunctions.length > 0) {
          for (let i = 0; i < this.mapFunctions.length; i++) {
            const f = this.mapFunctions[i];
            x = x.map(f);
          }
        }

        if (x.length > this.modifiedValue.length) {
          this._lastValue = x[x.length - 1];
        } else {
          this._lastValue = null;
        }

        this.modifiedValue = [...new Set(x)];

        this.$eventHub.$emit(this.eventName, this.modifiedValue);
      },
    },
  },

  watch: {
    value(newValue) {
      this.orignalValue = Object.assign([], newValue);
      this.modifiedValue = Object.assign([], newValue);
      this.loaded = true;
    },

    modifiedValue(newValue, oldValue) {
      if (
        oldValue.length &&
        newValue.length &&
        oldValue.length === newValue.length
      ) {
        let self = this;

        this._setDuplicateClass(this._lastValue);

        this.$refs.msgbox
          .open("Duplicate found", "Cannot add an item more than once")
          .then(() => {
            self.$refs.inputBox.focus();
          });
      }
    },
  },

  created() {
    if (!this.options) {
      return;
    }

    // first all of the map functions...
    if (this.options.containsExtensions) {
      this.mapFunctions.push(formatExtension);
    }

    if (this.options.lowerCase) {
      this.mapFunctions.push(lowerCase);
    }

    // next all of the rules

    if (this.options.domains) {
      this.rules.push(isValidDomain);
    }

    if (this.value) {
      this.orignalValue = Object.assign([], this.value);
      this.modifiedValue = Object.assign([], this.value);
      this.loaded = true;
    }
  },

  beforeDestroy() {
    if (this._intervalID) {
      clearInterval(this._intervalID);
    }
  },

  methods: {
    _setDuplicateClass(value) {
      let chips = this.$el.getElementsByClassName("v-chip__content");
      for (let i = 0; i < chips.length; i++) {
        const element = chips[i];
        if (element.innerText === value) {
          clearInterval(this._intervalID);
          element.classList.add("duplicate");
          this._intervalID = setInterval(this._RemoveDuplicateClass, 4000);
        }
      }
    },

    _RemoveDuplicateClass() {
      let chips = this.$el.getElementsByClassName("duplicate");
      for (let i = 0; i < chips.length; i++) {
        chips[i].classList.remove("duplicate");
      }
      clearInterval(this._intervalID);
    },

    onKeyDown() {
      this._RemoveDuplicateClass();
    },

    onBlur() {
      this._RemoveDuplicateClass();
    },

    _rulesRunner(values) {
      let result = null;

      if (this.loaded && this.options.required) {
        result = hasValue(values);
        if (isString(result)) {
          return result;
        }
      }

      if (!values || values.length === 0) {
        return true;
      }

      let rule = null;

      for (let i = 0; i < this.rules.length; i++) {
        rule = this.rules[i];

        for (let v = 0; v < values.length; v++) {
          const val = values[v];

          result = rule(val);
          if (isString(result)) {
            return result;
          }
        }
      }

      // every value in values passed every rules so return true
      return true;
    },

    revertValue() {
      if (deepEqual(this.modifiedValue, this.orignalValue)) {
        this.$refs.msgbox.open(
          "Nothing to undo",
          "Current value equals the original value"
        );
      } else {
        this.modifiedValue = Object.assign([], this.orignalValue);
        this.$eventHub.$emit(this.eventName, this.modifiedValue);
      }
    },

    clearValue() {
      this.modifiedValue = [];
      this.$eventHub.$emit(this.eventName, this.modifiedValue);
    },

    helpURL: function () {
      return getUploadConfigHelpURL(this.helpSection);
    },

    requiredValue(value) {
      if (!this.required) {
        return true;
      }

      if (value instanceof Array && value.length == 0) {
        return "Required.";
      }

      return !!value || "Required.";
    },
  },
};
</script>

<style>
/* .list-button {
  display: inline;
} */

.upload-config-control .v-label--active {
  padding-bottom: 30px;
  font-size: 1.3em;
  position: absolute;
  top: -3px;
}

.duplicate {
  color: red;
  font-weight: bold;
}

.errorState {
  color: red;
}
</style>
