<template>
  <v-card class="mx-auto ml-8" outlined>
    <div class="upload-config-control ml-7 mt-5">Filename</div>

    <ListEditor
      class="ml-10 mr-8 mt-1"
      v-bind:value="modifiedValue['startswith']"
      title="Starts with"
      eventName="format-filename-starts-with-updated"
      v-bind:options="{
        lowerCase: true,
      }"
    />

    <ListEditor
      class="ml-10 mr-8 mt-1"
      v-bind:value="modifiedValue['contains']"
      title="Contains"
      eventName="format-filename-contains-updated"
      v-bind:options="{
        lowerCase: true,
      }"
    />

    <ListEditor
      class="ml-10 mr-8 mt-1"
      v-bind:value="modifiedValue['endswith']"
      title="Ends with"
      eventName="format-filename-ends-with-updated"
      v-bind:options="{
        lowerCase: true,
      }"
    />
  </v-card>
</template>

<script>
import ListEditor from "@/components/upload/config/ListEditor.vue";

export default {
  components: {
    ListEditor,
  },

  props: {
    value: {
      type: Object,
      required: false,
    },
    options: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      orignalValue: {},
      modifiedValue: {},
      loaded: false,
    };
  },

  created() {
    if (this.value) {
      this.orignalValue = Object.assign({}, this.value);
      this.modifiedValue = Object.assign({}, this.value);
      this.loaded = true;
    }
  },

  watch: {
    value(newValue) {
      this.orignalValue = Object.assign({}, newValue);
      this.modifiedValue = Object.assign({}, newValue);
      this.loaded = true;
    },
  },
};
</script>

<style></style>
