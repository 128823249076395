import {
  isValidRegExp as isRE,
  isInteger as isInt,
  hasValue as hasVal,
  isValidDomain as isValDomain,
} from "@/lib/utils.js";

export function hasValue(value) {
  if (hasVal(value)) {
    return true;
  } else {
    return "Required";
  }
}

export function isValidRegExp(value) {
  if (isRE(value)) {
    return true;
  } else {
    return "Value must be a valid reqular expression";
  }
}

export function isInteger(value) {
  if (isInt(value)) {
    return true;
  } else {
    return "Value must be an integer";
  }
}

export function isValidDomain(value) {
  if (isValDomain(value)) {
    return true;
  } else {
    return "Value must be a valid domain";
  }
}
