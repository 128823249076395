<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="upload-config-control">
          <v-row>
            <v-col cols="9" class="pb-0">
              <v-text-field
                ref="inputBox"
                v-model="newColumnName"
                label="Add column"
                spellcheck="false"
                v-on:keydown="handleKeydown"
                class="pb-0"
              ></v-text-field>
            </v-col>

            <v-col>
              <v-row>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class="list-button mt-3"
                      v-on:click="addColumn"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Column</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class="list-button mt-3 ml-1"
                      v-on:click="revertValue"
                    >
                      <v-icon>mdi-undo-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Undo all changes</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pt-0">
              <v-data-table
                dense
                :items-per-page="500"
                hide-default-header
                hide-default-footer
                :sort-by.sync="sortBy"
                v-bind:headers="headers"
                v-bind:items="modifiedValue"
                class="elevation-1 mt-0"
              >
                <template v-slot:item.actions="{ item }">
                  <v-row>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          text
                          icon
                          v-on="on"
                          color="primary"
                          class="d-inline"
                          @click="moveUp(item)"
                        >
                          <v-icon d-inline small>mdi-arrow-up</v-icon>
                        </v-btn>
                      </template>
                      <span>Move column up</span>
                    </v-tooltip>

                    <v-btn
                      text
                      icon
                      class="d-inline"
                      color="primary"
                      @click="moveDown(item)"
                    >
                      <v-icon d-inline small>mdi-arrow-down</v-icon>
                    </v-btn>

                    <v-btn
                      text
                      icon
                      class="d-inline"
                      @click="updateColumn(item)"
                      color="primary"
                    >
                      <v-icon d-inline small>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                      text
                      icon
                      class="d-inline"
                      @click="deleteColumn(item)"
                      color="deep-orange"
                    >
                      <v-icon d-inline small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <sr-msg-box ref="msgBox"></sr-msg-box>
    <SimpleInputBox ref="updateBox"></SimpleInputBox>
  </v-container>
</template>

<script>
import SimpleInputBox from "@/components/utils/SimpleInputBox";
import { hasValue, isString } from "@/lib/utils.js";

export default {
  components: {
    SimpleInputBox,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
      required: false,
    },
    eventName: {
      type: String,
      required: false,
    },
    options: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      newColumnName: "",
      sortBy: "position",
      modifiedValue: [],
      orignalValue: [],

      headers: [
        { text: "Column", value: "name" },
        { text: "Actions", value: "actions" },
      ],
    };
  },

  computed: {
    computedValue: {
      get: function () {
        return this.modifiedValue.slice();
      },

      set: function (newValue) {
        let x = newValue;

        if (x.length > this.modifiedValue.length) {
          this._lastValue = x[x.length - 1];
        } else {
          this._lastValue = null;
        }

        this.modifiedValue = [...new Set(x)];

        this.$eventHub.$emit(this.eventName, this.modifiedValue);
      },
    },
  },

  created() {
    if (this.value) {
      this.setDataValues(this.value);
    }
  },

  watch: {
    value(newValue) {
      this.setDataValues(newValue);
    },
  },

  methods: {
    setDataValues(newValue) {
      let arr = [];

      for (let i = 0; i < newValue.length; i++) {
        const col = newValue[i];
        arr.push({ id: i, position: i, name: col.name });
      }

      this.orignalValue = Object.assign([], arr);
      this.modifiedValue = Object.assign([], this.orignalValue);
    },

    moveUp(item) {
      if (item.position === 0) {
        return;
      }

      const newPosition = item.position - 1;
      const currentCol = this.modifiedValue.find(
        (c) => c.position === newPosition
      );

      // now swap
      currentCol.position = item.position;
      item.position = newPosition;
    },

    moveDown(item) {
      if (item.position === this.modifiedValue.length - 1) {
        return;
      }

      const newPosition = item.position + 1;
      const currentCol = this.modifiedValue.find(
        (c) => c.position === newPosition
      );

      // now swap
      currentCol.position = item.position;
      item.position = newPosition;
    },

    addColumn() {
      // trim and make sure it
      let newCol = this.formatColumnName(this.newColumnName);

      if (!newCol) {
        return;
      }

      if (this.columnExists(newCol)) {
        // tell the user that we can't have duplicates
        const self = this;
        this.$refs.msgBox
          .open("Duplicate found", "Cannot add an item more than once")
          .then(() => {
            self.$refs.inputBox.focus();
          });

        return;
      }

      let maxInfo = this.getMaxInfo();
      this.modifiedValue.push({
        id: maxInfo.id + 1,
        position: maxInfo.position + 1,
        name: newCol,
      });

      this.newColumnName = "";
    },

    columnExists(value) {
      let result = this.modifiedValue.find((obj) => {
        return obj.name === value;
      });

      return !!result;
    },

    formatColumnName(value) {
      if (hasValue(value) && isString(value)) {
        return value.trim().toLowerCase();
      } else {
        return "";
      }
    },

    updateColumn(item) {
      const self = this;
      this.$refs.updateBox.open("Update Column", item.name).then(() => {
        // debugger;
        let newName = this.formatColumnName(self.$refs.updateBox.value);

        if (newName) {
          if (this.columnExists(newName)) {
            // tell the user that we can't have duplicates
            this.$refs.msgBox.open(
              "Duplicate found",
              "Cannot add an item more than once"
            );
            return;
          }

          item.name = newName;
        }
      });
    },

    deleteColumn(item) {
      for (let i = 0; i < this.modifiedValue.length; i++) {
        const col = this.modifiedValue[i];
        if (col.position > item.position) {
          col.position = col.position - 1;
        }
      }

      this.modifiedValue = this.modifiedValue.filter((x) => x.id !== item.id);
    },

    revertValue() {
      this.modifiedValue = Object.assign([], this.orignalValue);
      this.$eventHub.$emit(this.eventName, this.modifiedValue);
    },

    getMaxInfo() {
      let position = 0;
      let maxPosition = 0;

      let id = 0;
      let maxId = 0;

      for (let i = 0; i < this.modifiedValue.length; i++) {
        position = this.modifiedValue[i].position;
        id = this.modifiedValue[i].id;

        maxPosition = position > maxPosition ? position : maxPosition;
        maxId = id > maxId ? id : maxId;
      }

      return { position: maxPosition, id: maxId };
    },

    handleKeydown(e) {
      if (e.code === "Enter") {
        this.addColumn();
      }
    },
  },
};
</script>

<style></style>
