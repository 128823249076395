var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"upload-config-control"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"9"}},[_c('v-text-field',{ref:"inputBox",staticClass:"pb-0",attrs:{"label":"Add column","spellcheck":"false"},on:{"keydown":_vm.handleKeydown},model:{value:(_vm.newColumnName),callback:function ($$v) {_vm.newColumnName=$$v},expression:"newColumnName"}})],1),_c('v-col',[_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"list-button mt-3",attrs:{"icon":""},on:{"click":_vm.addColumn}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add Column")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"list-button mt-3 ml-1",attrs:{"icon":""},on:{"click":_vm.revertValue}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-undo-variant")])],1)]}}])},[_c('span',[_vm._v("Undo all changes")])])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 mt-0",attrs:{"dense":"","items-per-page":500,"hide-default-header":"","hide-default-footer":"","sort-by":_vm.sortBy,"headers":_vm.headers,"items":_vm.modifiedValue},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"d-inline",attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.moveUp(item)}}},on),[_c('v-icon',{attrs:{"d-inline":"","small":""}},[_vm._v("mdi-arrow-up")])],1)]}}],null,true)},[_c('span',[_vm._v("Move column up")])]),_c('v-btn',{staticClass:"d-inline",attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.moveDown(item)}}},[_c('v-icon',{attrs:{"d-inline":"","small":""}},[_vm._v("mdi-arrow-down")])],1),_c('v-btn',{staticClass:"d-inline",attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.updateColumn(item)}}},[_c('v-icon',{attrs:{"d-inline":"","small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"d-inline",attrs:{"text":"","icon":"","color":"deep-orange"},on:{"click":function($event){return _vm.deleteColumn(item)}}},[_c('v-icon',{attrs:{"d-inline":"","small":""}},[_vm._v("mdi-trash-can-outline")])],1)],1)]}}])})],1)],1)],1)])],1),_c('sr-msg-box',{ref:"msgBox"}),_c('SimpleInputBox',{ref:"updateBox"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }