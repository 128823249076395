<template>
  <div class="upload-config-control">
    <v-row>
      <v-select
        label="Data Type *"
        v-bind:items="items"
        v-model="computedValue"
      >
      </v-select>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="list-button mt-3"
            v-on:click="revertValue"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Undo all changes</span>
      </v-tooltip>
    </v-row>
    <sr-msg-box ref="msgbox"></sr-msg-box>
  </div>
</template>

<script>
import { ruleFails } from "@/lib/utils.js";
import { hasValue } from "@/lib/validators/common.js";

export default {
  data() {
    return {
      modifiedValue: "",
      originalValue: "",
      items: [
        "category-sales",
        "foodservice-sales",
        "warehouse-sales",
        "canteen-selections",
        "market-share",
        "planned-arrivals",
      ],
      rules: [],
    };
  },

  props: {
    value: {
      type: String,
      required: false,
    },
    eventName: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    computedValue: {
      get: function () {
        return this.modifiedValue;
      },

      set: function (newValue) {
        this.modifiedValue = newValue;
      },
    },
  },

  watch: {
    value(newValue) {
      this.originalValue = newValue;
      this.modifiedValue = newValue;
    },
  },

  created() {
    this.rules.push(hasValue);

    if (this.value) {
      this.originalValue = this.value;
      this.modifiedValue = this.value;
    }
  },

  methods: {
    clearValue() {
      this.modifiedValue = "";
      this.$eventHub.$emit(this.eventName, this.modifiedValue);
    },

    revertValue() {
      this.modifiedValue = this.originalValue;
      this.$eventHub.$emit(this.eventName, this.modifiedValue);
    },

    onBlur() {
      let fails = false;

      for (let i = 0; i < this.rules.length; i++) {
        fails = ruleFails(this.rules[i], this.modifiedValue);
        if (fails) {
          break;
        }
      }

      if (fails) {
        return;
      } else {
        this.$eventHub.$emit(this.eventName, this.modifiedValue);
      }
    },
  },
};
</script>

<style></style>
